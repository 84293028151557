import React, { Component } from 'react';
import {
    Col, Row, Card, CardBody, Container, Table,
    Nav, NavItem, NavLink, Input
} from 'reactstrap';
import PaginationCustom from '../../components/pagination/Pagination';
import { Link } from "react-router-dom";
import Breadcrumbs from '../../components/Common/Breadcrumb';

class CustomersFrequent extends Component {
    state = {
        breadcrumbItems: [
            { title: "Clientes", link: "#" },
            { title: 'Frecuentes', link: "#" }
        ],
        activeTab: '0'
    }

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    render() {
        return (
            <div>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title="Clientes frecuentes" breadcrumbItems={this.state.breadcrumbItems} />
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody className="without-padding">
                                        <Nav tabs className="nav-tabs-custom mb-4">
                                            <NavItem>
                                                <NavLink onClick={() => { this.toggleTab('0'); }} className={`${this.state.activeTab === '0' && 'active'} font-weight-bold p-3`}>Por compras</NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink onClick={() => { this.toggleTab('1'); }} className={`${this.state.activeTab === '1' && 'active'} font-weight-bold p-3`}>Por participaciones</NavLink>
                                            </NavItem>
                                        </Nav>
                                        <div class="row">
                                            <div className="col-6">
                                                <div className="form-inline mb-2">
                                                    <div className="search-box">
                                                        <div className="position-relative">
                                                            <Input type="text" className="form-control rounded" placeholder="Buscar" />
                                                            <i className="mdi mdi-magnify search-icon"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="table-responsive">
                                            <Table className="mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Nombre</th>
                                                        <th>Teléfono</th>
                                                        <th>Dirección</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th scope="row">1</th>
                                                        <td>Federico Mendez</td>
                                                        <td>098694456</td>
                                                        <td>San José</td>
                                                        <td>
                                                            <React.Fragment>
                                                                <Link className="text-primary" id={"details"}><i className="mdi mdi-layers-search font-size-18"></i></Link>
                                                            </React.Fragment>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                        <PaginationCustom page={1} pages={5} />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        );
    }
}

export default CustomersFrequent;