import { userLogin } from "../../services/UserServices"
import { Actions } from "./Actions";
import { Constants } from "../../Constants";
var CryptoJS = require("crypto-js");

export const userLoginAction = (data) => {
    return async dispatch => {
        try {
            dispatch(userLoginActionLoading());
            const result = await userLogin(data);
            setLocalStorage(result.data);
            dispatch(userLoginActionSuccess({
                account: result.data.data
            }));
        } catch (error) {
            dispatch(userLoginActionError({
                'message': error.response ? error.response.data.message : Constants.GENERIC_ERROR
            }));
        }
    }
}

export const userLoginActionLoading = () => ({
    type: Actions.USER_LOGIN_LOADING
})

export const userLoginActionSuccess = (data) => ({
    type: Actions.USER_LOGIN_SUCCESS,
    data: data
})

export const userLoginActionError = (data) => ({
    type: Actions.USER_LOGIN_ERROR,
    data: data
})

export const userIsLoggedAction = () => {
    return dispatch => {
        dispatch(userLoginActionLoading());
        const result = getLocalStorage();
        if (result) {
            dispatch(userLoginActionSuccess({
                account: result
            }));
        } else {
            dispatch(userLoginActionError({
                'message': null
            }));
        }
    }
}

export const logoutAction = () => {
    return dispatch => {
        dispatch(userLoginActionLoading());
        removeLocalStorage();
        dispatch(userLoginActionSuccess({
            account: null
        }));
    }
}

const setLocalStorage = (data) => {
    localStorage.setItem(Constants.HASH_USER_SESSION,
        CryptoJS.AES.encrypt(JSON.stringify(data.data), Constants.KEY_USER_SESSION));
}

const removeLocalStorage = (data) => {
    localStorage.removeItem(Constants.HASH_USER_SESSION);
}

const getLocalStorage = () => {
    let result = localStorage.getItem(Constants.HASH_USER_SESSION);
    if (result) {
        let bytes = CryptoJS.AES.decrypt(result, Constants.KEY_USER_SESSION);
        let plaintext = bytes.toString(CryptoJS.enc.Utf8);
        return JSON.parse(plaintext);
    }
    return null;
} 