import React, { Component } from 'react';
import './ModalCreateLots.scss';
import { Container, Row, Col, Card, CardBody, FormGroup, Table } from 'reactstrap';
import { logoutAction } from '../../redux/actions/UserActions';

class ModalCreateLotsInPerson extends Component {

    close = () => {
        this.props.close();
    }

    render() {
        return (
            <div className="container-modal">
                <div className="content-modal">
                    <Container>
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <div onClick={() => this.close()} style={{ textAlign: 'right', cursor: 'pointer' }}>
                                            X
                                        </div>
                                        <div>
                                            <h4>Agregar lotes</h4>
                                        </div>
                                        <Table className="mb-0">
                                            <thead>
                                                <tr>
                                                    <th>Nombre (A)</th>
                                                    <th>Descripción (B)</th>
                                                    <th>Cantidad (C)</th>
                                                    <th>Precio base (D)</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.props.lots.map(item =>
                                                        <tr>
                                                            <td>{`${item.A}`}</td>
                                                            <td>{`${item.B !== null ? item.B : ''}`}</td>
                                                            <td>{`${item.C !== null ? item.C : 1}`}</td>
                                                            <td>{`${item.D !== null ? item.D : 0}`}</td>
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </Table>
                                        <FormGroup className="mb-0 text-right" style={{ marginTop: '1rem' }}>
                                            <div>
                                                <button onClick={() => this.close()} type="button" className="btn btn-secondary">Cerrar</button>
                                                <button onClick={() => this.props.createLots()} style={{ marginLeft: '0.5rem' }} type="submit" className="btn btn-primary">Guardar</button>
                                            </div>
                                        </FormGroup>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        );
    }
}

export default ModalCreateLotsInPerson;