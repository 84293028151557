import axios from "axios";
import { Constants } from "../../Constants";

const getLogoWebBase64 = async () => {
    try {
        return await axios.get(`${Constants.BASE_URL}logo_web_base64`);
    } catch (error) {
        throw error;
    }
}

export async function addWatermarkAndUpload(originalFile, callback, props, keepOriginalName = false) {
    // Crear un elemento canvas
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    // Crear un objeto Image para la imagen original
    const originalImage = new Image();
    // Convertir el archivo en una URL que se pueda usar como fuente para la imagen
    const originalImageUrl = URL.createObjectURL(originalFile);
    originalImage.src = originalImageUrl;

    originalImage.onload = async () => {
        // Establecer dimensiones del canvas iguales a la imagen original
        canvas.width = originalImage.width;
        canvas.height = originalImage.height;

        // Dibujar la imagen original en el canvas
        ctx.drawImage(originalImage, 0, 0);

        // Crear otro objeto Image para la marca de agua
        const watermarkImage = new Image();

        const logoWebBase64 = await getLogoWebBase64();
        watermarkImage.src = logoWebBase64.data.data.logo_web

        watermarkImage.onload = () => {
            // Ajustar el tamaño de la marca de agua
            const scale = 0.2; // Ajustar este valor al porcentaje deseado del ancho de la imagen principal
            const watermarkWidth = originalImage.width * scale;
            const watermarkHeight = watermarkImage.height * (watermarkWidth / watermarkImage.width);

            // Posición centrada en el eje X y alineada al borde inferior en el eje Y
            const x = (canvas.width / 2) - (watermarkWidth / 2);
            const y = canvas.height - watermarkHeight - 20; // desde el borde inferior

            // Dibujar la marca de agua en el canvas
            ctx.drawImage(watermarkImage, x, y, watermarkWidth, watermarkHeight);

            // Convertir el canvas en un Blob
            canvas.toBlob((blob) => {
                // Crear un nuevo archivo con el Blob resultante
                const fileWithWatermark = new File([blob], keepOriginalName ? originalFile.name : `image_${Date.now()}.jpeg`, { type: 'image/jpeg' });
                callback(fileWithWatermark)
            }, 'image/jpeg');
        };
    };
}