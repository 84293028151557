import axios from 'axios';
import { Constants } from '../Constants';


export const auctionAdd = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        return await axios.post(`${Constants.BASE_URL}auctions`, data, httpOptions);
    } catch (error) {
        throw error;
    }
}

export const sendPdfs = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        return await axios.post(`${Constants.BASE_URL}auctions/send_pdfs_customers`, data, httpOptions);
    } catch (error) {
        throw error;
    }
}

export const downloadPdfs = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        return await axios.post(`${Constants.BASE_URL}auctions/pdfs/download`, data, httpOptions);
    } catch (error) {
        throw error;
    }
}

export const downloadSigns = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        return await axios.post(`${Constants.BASE_URL}auctions/signs/download`, data, httpOptions);
    } catch (error) {
        throw error;
    }
}

export const auctionUpdate = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        return await axios.put(`${Constants.BASE_URL}auctions/${data.id}`, data, httpOptions);
    } catch (error) {
        throw error;
    }
}

export const auctionGet = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        return await axios.get(`${Constants.BASE_URL}auctions/${data.id}`, httpOptions);
    } catch (error) {
        throw error;
    }
}

export const auctionListAutocomplete = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        let query = '';
        if (data.all !== undefined) {
            query = '?all=true';
        }
        return await axios.get(`${Constants.BASE_URL}auctions/autocomplete/all${query}`, httpOptions);
    } catch (error) {
        throw error;
    }
}

export const auctionGetAll = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        return await axios.get(`${Constants.BASE_URL}auctions?page=${data.page}&type=${data.type}&search=${data.search}`, httpOptions);
    } catch (error) {
        throw error;
    }
}

export const deleteAuction = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        return await axios.delete(`${Constants.BASE_URL}auctions/${data.id}`, httpOptions);
    } catch (error) {
        throw error;
    }
}

export const auctionGetMoneys = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        let query = '';
        if (data.require_all) {
            query = '?require_all=true';
        }
        return await axios.get(`${Constants.BASE_URL}auctions/moneys${query}`, httpOptions);
    } catch (error) {
        throw error;
    }
}

export const getLogs = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        return await axios.get(`${Constants.BASE_URL}auction/logs/getLogs`, httpOptions);
    } catch (error) {
        throw error;
    }
}

export const getActualLots = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        return await axios.get(`${Constants.BASE_URL}auctions/actual/lots`, httpOptions);
    } catch (error) {
        throw error;
    }
}

export const addItemType = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        return await axios.post(`${Constants.BASE_URL}types/item`, data, httpOptions);
    } catch (error) {
        throw error;
    }
}

export const getItemsType = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        return await axios.get(`${Constants.BASE_URL}types/items`, httpOptions);
    } catch (error) {
        throw error;
    }
}

export const deleteItemType = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        return await axios.delete(`${Constants.BASE_URL}types/item/${data.id}`, httpOptions);
    } catch (error) {
        throw error;
    }

}

export const getConfigs = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        return await axios.get(`${Constants.BASE_URL}auctions/get/configs`, httpOptions);
    } catch (error) {
        throw error;
    }
}

export const putConfigs = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        return await axios.put(`${Constants.BASE_URL}auctions/put/configs`, data, httpOptions);
    } catch (error) {
        throw error;
    }
}

export const postLogsByAuction = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        return await axios.post(`${Constants.BASE_URL}auction/logs/getByAuction`, data, httpOptions);
    } catch (error) {
        throw error;
    }
}

export const getOffersConfig = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        return await axios.get(`${Constants.BASE_URL}offers/configs`, httpOptions);
    } catch (error) {
        throw error;
    }
}