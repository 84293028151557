import { Actions } from "./Actions";
import { getAccountConfigs } from "../../services/AccountServices";

export const configAccountAction = (data) => {
    return async dispatch => {
        try {
            const result = await getAccountConfigs(data);
            dispatch(configAccountActionSuccess({
                config: result.data.data.config
            }));
        } catch (error) {
            dispatch(configAccountActionSuccess({
                config: null
            }));
        }
    }
}

export const configAccountActionSuccess = (data) => ({
    type: Actions.GET_ACCOUNT_CONFIG,
    data: data
})