import { Actions } from "../actions/Actions";

const initialState = {
    config: null
}

export const accountReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.GET_ACCOUNT_CONFIG:
            return { ...state, config: action.data.config };
        default:
            return state;
    }
}