import React, { Component } from 'react';
import './Pagination.scss';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

class PaginationCustom extends Component {

    onPrev = () => {
        window.scrollTo(0, 0);
        this.props.onPrev();
    }

    onNext = () => {
        window.scrollTo(0, 0);
        this.props.onNext();
    }

    onPage = (page) => {
        window.scrollTo(0, 0);
        this.props.onPage(page);
    }

    render() {
        return (
            <Pagination aria-label="Page navigation example" className="pagination-rounded" style={{ marginTop: '0.5rem' }}>
                <PaginationItem disabled={this.props.page === 1 ? true : false}>
                    <span onClick={this.onPrev} className="page-link"><i className="mdi mdi-chevron-left"></i></span>
                </PaginationItem>
                {
                    this.props.page - 2 > 0 && this.props.page === this.props.pages &&
                    <PaginationItem onClick={() => this.onPage(this.props.page - 2)}><span className="page-link">{this.props.page - 2}</span></PaginationItem>
                }
                {
                    this.props.page - 1 !== 0 &&
                    <PaginationItem onClick={() => this.onPage(this.props.page - 1)}><span className="page-link">{this.props.page - 1}</span></PaginationItem>
                }
                <PaginationItem active>
                    <span className="page-link">{this.props.page}<span className="sr-only">(current)</span></span>
                </PaginationItem>
                {
                    this.props.page + 1 <= this.props.pages &&
                    <PaginationItem onClick={() => this.onPage(this.props.page + 1)}><span className="page-link">{this.props.page + 1}</span></PaginationItem>
                }
                {
                    this.props.page - 1 === 0 && this.props.page + 2 <= this.props.pages &&
                    <PaginationItem onClick={() => this.onPage(this.props.page + 2)}><span className="page-link">{this.props.page + 2}</span></PaginationItem>
                }
                <PaginationItem disabled={this.props.page === this.props.pages || this.props.page > this.props.pages ? true : false}>
                    <span onClick={this.onNext} className="page-link"><i className="mdi mdi-chevron-right"></i></span>
                </PaginationItem>
            </Pagination>
        );
    }
}

export default PaginationCustom;