import axios from 'axios';
import { Constants } from '../Constants';


export const auctionAdd = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        return await axios.post(`${Constants.BASE_URL}auctionsInPerson`, data, httpOptions);
    } catch (error) {
        throw error;
    }
}

export const setImagesAuction = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        return await axios.post(`${Constants.BASE_URL}auctionsInPerson/setImages`, data, httpOptions);
    } catch (error) {
        throw error;
    }
}

export const auctionUpdate = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        return await axios.put(`${Constants.BASE_URL}auctionsInPerson/${data.id}`, data, httpOptions);
    } catch (error) {
        throw error;
    }
}

export const auctionGet = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        return await axios.get(`${Constants.BASE_URL}auctionsInPerson/${data.id}`, httpOptions);
    } catch (error) {
        throw error;
    }
}

export const auctionListAutocomplete = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        let query = '';
        if (data.all !== undefined) {
            query = '?all=true';
        }
        return await axios.get(`${Constants.BASE_URL}auctionsInPerson/autocomplete/all${query}`, httpOptions);
    } catch (error) {
        throw error;
    }
}

export const auctionGetAll = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        return await axios.get(`${Constants.BASE_URL}auctionsInPerson?page=${data.page}&type=${data.type}&search=${data.search}`, httpOptions);
    } catch (error) {
        throw error;
    }
}

export const deleteAuction = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        return await axios.delete(`${Constants.BASE_URL}auctionsInPerson/${data.id}`, httpOptions);
    } catch (error) {
        throw error;
    }
}

export const auctionGetMoneys = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        let query = '';
        if (data.require_all) {
            query = '?require_all=true';
        }
        return await axios.get(`${Constants.BASE_URL}auctions/moneys${query}`, httpOptions);
    } catch (error) {
        throw error;
    }
}
