import axios from 'axios';
import { Constants } from '../Constants';


export const userLogin = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'content-type': 'application/json'
            }
        };
        return await axios.post(`${Constants.BASE_URL}login`, data, httpOptions);
    } catch (error) {
        throw error;
    }
}

export const generateLoginCode = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        return await axios.post(`${Constants.BASE_URL}customers/generateLoginCode`, data, httpOptions);
    } catch (error) {
        throw error;
    }
}

export const customersListAutocomplete = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        let query = '';
        if (data.onlyRemitter) {
            query = '?onlyRemitter=true';
        }
        if (data.auctionSelect) {
            if (query !== '') {
                query += '&auctionSelect=';
            }
            else {
                query = '?auctionSelect=';
            }
            query += data.auctionSelect;
        }
        if(data.addExtraData) {
            if (query !== '') {
                query += '&addExtraData=';
            }
            else {
                query = '?addExtraData=';
            }
            query += data.addExtraData;
        }
        return await axios.get(`${Constants.BASE_URL}customers/autocomplete/all${query}`, httpOptions);
    } catch (error) {
        throw error;
    }
}

export const getCustomers = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        let query = '';
        if (data.onlyRemitter) {
            query = '&onlyRemitter=true';
        }
        query += '&search=' + data.search;
        query += '&orderBy=' + data.orderBy;
        return await axios.get(`${Constants.BASE_URL}customers?page=${data.page}&filter=${data.filter}${query}`, httpOptions);
    } catch (error) {
        throw error;
    }
}

export const blockCustomer = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        let body = {}
        if (data.reasonBlock) {
            body = { reasonBlock: data.reasonBlock }
        }
        return await axios.post(`${Constants.BASE_URL}customers/block/${data.id}`, body, httpOptions);
    } catch (error) {
        throw error;
    }
}

export const unblockCustomer = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        return await axios.post(`${Constants.BASE_URL}customers/unblock/${data.id}`, {}, httpOptions);
    } catch (error) {
        throw error;
    }
}

export const departaments = async () => {
    try {
        return await axios.get(`${Constants.BASE_URL}departaments`);
    } catch (error) {
        throw error;
    }
}

export const getCustomer = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        return await axios.get(`${Constants.BASE_URL}customers/${data.customerId}`, httpOptions);
    } catch (error) {
        throw error;
    }
}

export const updateCustomer = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        return await axios.put(`${Constants.BASE_URL}customers/${data.customerId}`, data, httpOptions);
    } catch (error) {
        throw error;
    }
}

export const getSign = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        return await axios.post(`${Constants.BASE_URL}sign/get`, data, httpOptions);
    } catch (error) {
        throw error;
    }
}

export const addSign = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        return await axios.post(`${Constants.BASE_URL}sign/add`, data, httpOptions);
    } catch (error) {
        throw error;
    }
}

export const verificarRUT = (rut) => {
    rut = rut.replace(/\D/g, '');
    let _suma = 0;
    let _multiplicarPor = [];
    _multiplicarPor[0] = 4;
    _multiplicarPor[1] = 3;
    _multiplicarPor[2] = 2;
    _multiplicarPor[3] = 9;
    _multiplicarPor[4] = 8;
    _multiplicarPor[5] = 7;
    _multiplicarPor[6] = 6;
    _multiplicarPor[7] = 5;
    _multiplicarPor[8] = 4;
    _multiplicarPor[9] = 3;
    _multiplicarPor[10] = 2;

    let _digitosRUT = [];
    let len = rut.length;
    for (let i = 0; i < len; i++) {
        _digitosRUT[i] = parseInt(rut[i]);
    }
    let len2 = _multiplicarPor.length;
    for (let i = 0; i < len2; i++) {
        let _auxMultiplicacion = _digitosRUT[i] * _multiplicarPor[i];
        _suma = _suma + _auxMultiplicacion;

    }
    let _digitoVerificador = 11 - (_suma % 11);
    if (_digitoVerificador == 11)
        _digitoVerificador = 0;
    if (_digitoVerificador == _digitosRUT[11])
        return true;
    else
        return false;
}

export const verificarCI = (ci) => {
    ci = ci.replace(/\D/g, '');
    let dig = ci[ci.length - 1];
    ci = ci.replace(/[0-9]$/, '');
    let a = 0;
    let i = 0;
    if (ci.length <= 6) {
        for (i = ci.length; i < 7; i++) {
            ci = '0' + ci;
        }
    }
    for (i = 0; i < 7; i++) {
        a += (parseInt("2987634"[i]) * parseInt(ci[i])) % 10;
    }
    let validation_digit = null;
    if (a % 10 === 0) {
        validation_digit = 0;
    } else {
        validation_digit = 10 - a % 10;
    }
    return (dig == validation_digit);
}