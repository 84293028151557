import React, { Component } from 'react';
import Loading from '../../components/Loading';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { Container, Row, Col, Card, CardBody, FormGroup, Label, Input, Button, CustomInput } from 'reactstrap';
import './AccountConfig.scss';
import Select from 'react-select';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { getAccountConfigs, putAccountConfigs } from '../../services/AccountServices';
import { Constants } from '../../Constants';
import axios from 'axios';

class AccountConfig extends Component {
    state = {
        breadcrumbItems: [
            { title: "Cuenta", link: "#" },
            { title: "Configuración", link: "#" }
        ],
        logo_web: null,
        logo_admin: null,
        title_tabs: null,
        whatsapp: null,
        facebook: null,
        instagram: null,
        show_best_postor: null,
        footer_text_1: null,
        footer_text_2: null,
        footer_text_3: null,
        footer_text_4: null,
        footer_company_web: null,
        contact_address: null,
        contact_phone: null,
        contact_email: null,
        contact_map_lat: null,
        contact_map_lng: null,
        analytic_code: null,
        facebook_app_id: null,
        favicon_admin: null,
        favicon_web: null,
        footer_company_name: null,
        include_page_conditions: null,
        generate_catalog_automatic: null,
        socket_api_key: null,
        show_department: null,
        socket_api_secret: null,
        comision_with_iva: null,
        include_page_sell: 0,
        include_page_buy: 0,
        include_page_faq: 0,
        include_about_us: 0,
        include_page_form_pay: 0,
        include_page_sell_buy: 0,
        added_water_mark: 0,
        use_cost_packing: 0,
    }

    componentDidMount = () => {
        toast.configure();
        this.fetchConfigs();
    }

    fetchConfigs = async () => {
        await this.setState({ loading: true });
        try {
            let response = await getAccountConfigs();
            const showBestPostor = { value: null, label: '' }
            if (response.data.data.config.show_best_postor === 1) {
                showBestPostor.value = 1;
                showBestPostor.label = 'Si';
            }
            else if (response.data.data.config.show_best_postor === 2) {
                showBestPostor.value = 2;
                showBestPostor.label = 'Si con *';
            }
            else {
                showBestPostor.value = 0;
                showBestPostor.label = 'No';
            }
            await this.setState({
                loading: false,
                logo_web: response.data.data.config.logo_web,
                logo_admin: response.data.data.config.logo_admin,
                title_tabs: response.data.data.config.title_tabs,
                whatsapp: response.data.data.config.whatsapp,
                facebook: response.data.data.config.facebook,
                instagram: response.data.data.config.instagram,
                show_best_postor: showBestPostor,
                generate_catalog_automatic: response.data.data.config.generate_catalog_automatic ? { value: 1, label: 'Si' } : { value: 0, label: 'No' },
                footer_text_1: response.data.data.config.footer_text_1,
                footer_text_2: response.data.data.config.footer_text_2,
                footer_text_3: response.data.data.config.footer_text_3,
                footer_text_4: response.data.data.config.footer_text_4,
                footer_company_web: response.data.data.config.footer_company_web,
                contact_address: response.data.data.config.contact_address,
                contact_phone: response.data.data.config.contact_phone,
                contact_email: response.data.data.config.contact_email,
                contact_map_lat: response.data.data.config.contact_map_lat,
                contact_map_lng: response.data.data.config.contact_map_lng,
                analytic_code: response.data.data.config.analytic_code,
                facebook_app_id: response.data.data.config.facebook_app_id,
                favicon_admin: response.data.data.config.favicon_admin,
                favicon_web: response.data.data.config.favicon_web,
                footer_company_name: response.data.data.config.footer_company_name,
                include_page_conditions: response.data.data.config.include_page_conditions ? { value: 1, label: 'Si' } : { value: 0, label: 'No' },
                socket_api_key: response.data.data.config.socket_api_key,
                show_department: response.data.data.config.show_department ? { value: 1, label: 'Si' } : { value: 0, label: 'No' },
                socket_api_secret: response.data.data.config.socket_api_secret,
                comision_with_iva: response.data.data.config.comision_with_iva ? { value: 1, label: 'Si' } : { value: 0, label: 'No' },
                include_page_sell: response.data.data.config.include_page_sell ? { value: 1, label: 'Si' } : { value: 0, label: 'No' },
                include_page_buy: response.data.data.config.include_page_buy ? { value: 1, label: 'Si' } : { value: 0, label: 'No' },
                include_page_faq: response.data.data.config.include_page_faq ? { value: 1, label: 'Si' } : { value: 0, label: 'No' },
                include_about_us: response.data.data.config.include_about_us ? { value: 1, label: 'Si' } : { value: 0, label: 'No' },
                include_page_form_pay: response.data.data.config.include_page_form_pay ? { value: 1, label: 'Si' } : { value: 0, label: 'No' },
                include_page_sell_buy: response.data.data.config.include_page_sell_buy ? { value: 1, label: 'Si' } : { value: 0, label: 'No' },
                added_water_mark: response.data.data.config.added_water_mark ? { value: 1, label: 'Si' } : { value: 0, label: 'No' },
                use_cost_packing: response.data.data.config.use_cost_packing ? { value: 1, label: 'Si' } : { value: 0, label: 'No' },
            })
        } catch (error) {
            await this.setState({ loading: false });
        }
    }

    handleSelectChange = async (selectedOption, name) => {
        await this.setState({
            [name]: selectedOption
        });
    };

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({
            ...this.state,
            [name]: value
        });
    }

    save = async (e) => {
        e.preventDefault();
        await this.setState({ loading: true });
        try {
            let response = await putAccountConfigs({
                token: this.props.sessionProps.account.token,
                logo_web: this.state.logo_web,
                logo_admin: this.state.logo_admin,
                title_tabs: this.state.title_tabs,
                whatsapp: this.state.whatsapp,
                facebook: this.state.facebook,
                instagram: this.state.instagram,
                show_best_postor: this.state.show_best_postor.value,
                generate_catalog_automatic: this.state.generate_catalog_automatic.value,
                footer_text_1: this.state.footer_text_1,
                footer_text_2: this.state.footer_text_2,
                footer_text_3: this.state.footer_text_3,
                footer_text_4: this.state.footer_text_4,
                footer_company_web: this.state.footer_company_web,
                contact_address: this.state.contact_address,
                contact_phone: this.state.contact_phone,
                contact_email: this.state.contact_email,
                contact_map_lat: this.state.contact_map_lat,
                contact_map_lng: this.state.contact_map_lng,
                analytic_code: this.state.analytic_code,
                facebook_app_id: this.state.facebook_app_id,
                favicon_web: this.state.favicon_web,
                favicon_admin: this.state.favicon_admin,
                footer_company_name: this.state.footer_company_name,
                include_page_conditions: this.state.include_page_conditions.value,
                socket_api_key: this.state.socket_api_key,
                show_department: this.state.show_department.value,
                socket_api_secret: this.state.socket_api_secret,
                comision_with_iva: this.state.comision_with_iva.value,
                include_page_sell: this.state.include_page_sell.value,
                include_page_buy: this.state.include_page_buy.value,
                include_page_faq: this.state.include_page_faq.value,
                include_about_us: this.state.include_about_us.value,
                include_page_form_pay: this.state.include_page_form_pay.value,
                include_page_sell_buy: this.state.include_page_sell_buy.value,
                added_water_mark: this.state.added_water_mark.value,
                use_cost_packing: this.state.use_cost_packing.value,
            });
            await this.setState({
                loading: false
            })
            toast(`Configuraciónes guardadas con éxito`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
        } catch (error) {
            await this.setState({ loading: false });
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                let msg = 'Ocurrió un error, reintentar.';
                if (error !== undefined && error.response !== undefined && error.response.data !== undefined) {
                    msg = error.response.data.message;
                }
                toast.error(msg, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    handleFileChange = async (event, name) => {
        for (let file of event.target.files) {
            var form = new FormData();
            form.append('file', file);
            await this.setState({ loading: true });
            try {
                const result = await axios.post(`${Constants.BASE_URL}auctions/image_upload?type=account`, form, {
                    headers: {
                        'Authorization': this.props.sessionProps.account.token,
                        'content-type': 'multipart/form-data'
                    }
                });
                await this.setState({
                    [name]: result.data.data.image,
                    loading: false
                });
            } catch (error) {
                await this.setState({ loading: false });
                if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                    this.props.logout();
                    this.props.history.push('/');
                }
                else {
                    let msg = 'Ocurrió un error, reintentar.';
                    if (error !== undefined && error.response !== undefined && error.response.data !== undefined) {
                        msg = error.response.data.message;
                    }
                    toast.error(msg, {
                        position: toast.POSITION.BOTTOM_CENTER,
                        pauseOnHover: false,
                        duration: 10000
                    });
                }
            }
        }
    }

    inputFileUpload = ({ title, name }) => (
        <FormGroup row>
            <Label className="col-md-2 col-form-label">{title}</Label>
            <Col md={8}>
                <div className="custom-file">
                    <CustomInput type="file" name="file" className="custom-file-input" onChange={(e) => this.handleFileChange(e, name)} />
                    <Label className="custom-file-label">Seleccionar {title}</Label>
                </div>
            </Col>
            <Col md={2}>
                {
                    this.state[name] !== null &&
                    <img
                        style={{ height: '100%', width: 'auto', maxHeight: '80px' }}
                        className="avatar-sm rounded bg-light"
                        src={`${Constants.BASE_URL}${this.state[name]}`}
                    />
                }
            </Col>
        </FormGroup>
    )

    input = ({ title, name, placeholder, disable }) => (
        <FormGroup row>
            <Label className="col-md-2 col-12 col-form-label">{title}</Label>
            <div className="col-md-10 col-12">
                <Input name={name} className="form-control" type="text"
                    placeholder={placeholder} disabled={disable}
                    value={this.state[name]} onChange={this.handleChange} />
            </div>
        </FormGroup>
    )

    select = ({ title, name, extraOption }) => (
        <FormGroup row>
            <Col md={2}>
                <Label className="col-form-label">{title}</Label>
            </Col>
            <Col md={10}>
                <Select
                    name={name}
                    className="select2 select2-multiple"
                    options={[{ value: 0, label: 'No' }, { value: 1, label: 'Si' }, ...(extraOption || [])]}
                    value={this.state[name]}
                    onChange={(sl) => this.handleSelectChange(sl, name)}
                    required
                />
            </Col>
        </FormGroup>
    )

    handleSelectChange = async (selectedOption, name) => {
        await this.setState({
            [name]: selectedOption
        });
    };

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    {this.state.loading && <Loading />}
                    <Container fluid>
                        <Breadcrumbs title="Configuración cuenta" breadcrumbItems={this.state.breadcrumbItems} />
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <form onSubmit={this.save}>
                                            <h5 style={{ marginBottom: '1rem' }}>Logos</h5>
                                            <this.inputFileUpload name="logo_web" title="Logo web" />
                                            <this.inputFileUpload name="favicon_web" title="Favicon web" />
                                            <this.inputFileUpload name="logo_admin" title="Logo admin" />
                                            <this.inputFileUpload name="favicon_admin" title="Favicon admin" />

                                            <h5 style={{ marginBottom: '1rem' }}>Web pública</h5>
                                            <this.input name="title_tabs" title="Título tabs"
                                                placeholder="Ingrese el título para los tabs" />
                                            <this.input name="whatsapp" title="Whatsapp"
                                                placeholder="Format: 59898111222" />
                                            <this.input name="facebook" title="Facebook"
                                                placeholder="url perfil" />
                                            <this.input name="instagram" title="Instagram"
                                                placeholder="url perfil" />
                                            <this.input name="analytic_code" title="Código google analytic"
                                                placeholder="Ingrese código de google analytic" />
                                            <this.input name="facebook_app_id" title="Facebook APP ID"
                                                placeholder="Ingrese el id de la app de facebook" disable />
                                            <this.input name="socket_api_key" title="PieSocket API KEY"
                                                placeholder="Ingrese el api key de PieSocket" disable />
                                            <this.input name="socket_api_secret" title="PieSocket API SECRET"
                                                placeholder="Ingrese el api secret de PieSocket" disable />

                                            <h5 style={{ marginBottom: '1rem' }}>Footer</h5>
                                            <this.input name="footer_text_1" title="Footer texto 1"
                                                placeholder="Ingrese texto footer 1" />
                                            <this.input name="footer_text_2" title="Footer texto 2"
                                                placeholder="Ingrese texto footer 2" />
                                            <this.input name="footer_text_3" title="Footer texto 3"
                                                placeholder="Ingrese texto footer 3" />
                                            <this.input name="footer_text_4" title="Footer texto 4"
                                                placeholder="Ingrese texto footer 4" />
                                            <this.input name="footer_company_web" title="Footer web de la empresa"
                                                placeholder="Ingrese web de la empresa" />
                                            <this.input name="footer_company_name" title="Footer nombre de la empresa"
                                                placeholder="Ingrese nombre de la empresa" />

                                            <h5 style={{ marginBottom: '1rem' }}>Contacto</h5>
                                            <this.input name="contact_address" title="Dirección"
                                                placeholder="Ingrese contacto dirección" />
                                            <this.input name="contact_phone" title="Teléfono"
                                                placeholder="Ingrese contacto teléfono" />
                                            <this.input name="contact_email" title="Email"
                                                placeholder="Ingrese contacto email" />
                                            <this.input name="contact_map_lat" title="Latitud"
                                                placeholder="Ingrese contacto latitud" />
                                            <this.input name="contact_map_lng" title="longitud"
                                                placeholder="Ingrese contacto longitud" />

                                            <h5 style={{ marginBottom: '1rem' }}>Funcionalidades</h5>
                                            <this.select title="Mostrar mejor postor" name="show_best_postor" extraOption={[{ value: 2, label: 'Si con *' }]} />
                                            <this.select title="Pág. condiciones" name="include_page_conditions" />
                                            <this.select title="Catálogo automático" name="generate_catalog_automatic" />
                                            <this.select title="Muestra departamento" name="show_department" />
                                            <this.select title="Comisión con iva" name="comision_with_iva" />
                                            <this.select title="Pág. vender" name="include_page_sell" />
                                            <this.select title="Pág. comprar" name="include_page_buy" />
                                            <this.select title="Pág. faq" name="include_page_faq" />
                                            <this.select title="Pág. sobre nosotros" name="include_about_us" />
                                            <this.select title="Pág. formas de pago" name="include_page_form_pay" />
                                            <this.select title="Pág. vender y comprar" name="include_page_sell_buy" />
                                            <this.select title="Marca de agua" name="added_water_mark" />
                                            <this.select title="Costo de embalaje" name="use_cost_packing" />

                                            <FormGroup className="mb-0 text-right">
                                                <div>
                                                    <Button type="submit" color="primary" className="ml-2">
                                                        Guardar
                                                    </Button>
                                                </div>
                                            </FormGroup>
                                        </form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    sessionProps: state.userReducer
})

export default connect(mapStateToProps, null)(AccountConfig);