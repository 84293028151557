import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";

// Dashboard
import Dashboard from "../pages/Dashboard/index";
import AuctionsList from "../pages/Auctions/AuctionsList";
import AuctionsAddEdit from "../pages/Auctions/AuctionsAddEdit";
import BannersList from "../pages/Banners/BannersList";
import CustomersList from "../pages/Customers/CustomersList";
import CustomersFrequent from "../pages/Customers/CustomersFrequent";
import HelpPage from "../pages/HelpPage/HelpPage";
import LotsDeliver from "../pages/Lots/LotsDeliver";
import ActualAuctionLots from "../pages/Auctions/ActualAuctionLots";
import ActualAuctionLogs from "../pages/Auctions/ActualAuctionLogs";
import AuctionTypes from "../pages/Auctions/AuctionTypes";
import AuctionConfig from "../pages/Auctions/AuctionConfig";
import AccountConfig from "../pages/Account/AccountConfig";
import RemitterLiquid from "../pages/Remitter/RemitterLiquid";
import AuctionsInPersonList from "../pages/Auctions_in_person/AuctionsList";
import AuctionsInPersonAddEdit from "../pages/Auctions_in_person/AuctionsAddEdit";
import Files from "../pages/Files/Files";

const privateRoutes = [
	{ path: "/dashboard", component: Dashboard },
	{ path: "/remates/actual/lotes", component: ActualAuctionLots },
	{ path: "/remates/actual/logs", component: ActualAuctionLogs },
	{ path: "/remates/tipos", component: AuctionTypes },
	{ path: "/remates/configs", component: AuctionConfig },
	{ path: "/remates/:id", component: AuctionsAddEdit },
	{ path: "/remates", component: AuctionsList },

	{ path: "/remates-presenciales/:id", component: AuctionsInPersonAddEdit },
	{ path: "/remates-presenciales", component: AuctionsInPersonList },

	{ path: "/lotes/entregar", component: LotsDeliver },
	{ path: "/banners", component: BannersList },

	{ path: "/clientes/frecuentes", component: CustomersFrequent },
	{ path: "/clientes", component: CustomersList },
	{ path: "/pagina/:type", component: HelpPage },

	{ path: "/liquidar-remitentes", component: RemitterLiquid },

	{ path: "/cuenta/configuracion", component: AccountConfig },

	{ path: "/archivos", component: Files },

	{ path: "/", exact: true, component: () => <Redirect to="/remates" /> }
];

const publicRoutes = [
	{ path: "/logout", component: Logout },
	{ path: "/login", component: Login },
	{ path: "", component: Login }
];

export { privateRoutes, publicRoutes };